.list-companies{
    min-width: 400px;
    .header{
        margin-bottom: 20px;
        font-weight: 600;
        font-size: 18px;
    }
    .document-info{

        margin-bottom: 30px;
        .time-update{
            .value{
                font-weight: 600;
            }
            margin-bottom: 10px;
            display: block;
        }
        .file-name{
            padding: 10px;
            box-shadow: 0 0 4px rgba(3,3,3,.3);
            margin: 10px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            &:hover{
                background-color: var(--main-color);
            }
            .details{
                display: flex;
                align-items: center;
            }
            .progress-icon{
              &.done{
                fill: var(--notify-passed);
              }
              &.loading{
                animation: animLoader .5s infinite;
                fill: var(--niotify-warning);
              }
              &.error{
                fill: var(--notify-alert);
              }
            }
          }
    }
}