.container {
    width: 100%;
    padding: 15px;
    // max-width: 1010px;
    // max-width: 911px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    @media (max-width: 600px) {
        padding-right: 15px;
        padding-left: 15px;
    }
}  

.cotainer-dashboard{

    background-color: #fff;
    border-radius: 0.25rem;

    box-shadow: 2px 4px 20px rgba(0,0,0,.05);
}

.side-bar-menu{
    background-color: var(--main-color-dark);
    position: fixed;
    top: 0;
    left: 0;
    max-width: 200px;
    height: 100%;
    z-index: 999;
    width: 100%;
    overflow: hidden;
    overflow-y: auto;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    .side-bar-menu-inside{
        .active{
            background-color: var(--main-color);
        }
        .button-click{
            cursor: pointer;

            margin-bottom: 2px;
            &:hover{
                background-color: var(--main-color);
            }
            .button-click-inside{
                padding: 15px 10px;
                display: flex;
                align-items: center;
                position: relative;
                padding-left: 50px;
                height: 50px;
                .the-icon{
                    width: 50px;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    left: 0;
                    top: 0;
                    // background-color: var(--main-color);
                    .own_icon{
                        width: 25px;
                        height: 25px;
                    }
                }
                *{
                    color: #fff;
                    fill: #fff;
                }
                span{
                    margin-left: 5px;
                    // font-weight: 600;
                }
                
            }
        }
    }
    .side-bar-header{
        padding: 10px;
        display: flex;
        align-items: center;
        // margin-bottom: 20px;
        border-bottom: 2px solid #fff;
        min-height: 50px;
        background-color: #fff;
        border-right: 2px solid #f1f1f1;
        span{
            margin-left: 10px;
            display: block;
            
        }
        *{
            color: var(--main-color-dark);
            fill: var(--main-color-dark);
        }
    }
}
.side-gap{
    padding-left: 220px !important;
}
.top-gap{
    padding-top: 70px !important;
}

.content-wrapper{
    width: 100%;
    padding: 20px;
}
.top-menu{
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    height: 50px;
    width: 100%;
    background-color: #fff;
    z-index: 998;
    .top-menu-inside{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 15px;
        .user-name{
            display: flex;
            align-items: center;
            span{
                margin-left: 10px;
            }
        }
        .header-title{
            font-weight: 600;
            text-transform: capitalize;
        }
    }
}

.wrapper-head{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.show{
    display: block !important;
}
.cursor-pointer{
    cursor: pointer !important;
}