.form-otp{
    max-width: 300px;
    .input-text{
        width: 100%;
        display: flex;
        // padding: 20px;
        input{
            flex: 1;
            width: calc(100%/6);
            padding: 10px;
            height: 50px;
            text-align: center;
            border: 0;
            outline: none;
            background-color: #fff;
            margin-right: 5px;
            box-shadow: 0 0 10px rgba(0,0,0,.3);
            &:last-child{
                margin-right: 0;
            }
        }
        div{
            height: 50px;
            min-width: 50px;
            flex: 1;
        }
        .focused{
            border: 2px solid red;
        }
    }
    .otp-header{
        margin-bottom: 20px;
        font-weight: 600;
        font-size: 18px;
    }
    .otp-timer{
        margin-top: 20px;
        font-size: 14px;
        font-weight: 600;
        /* text-align: right; */
        display: flex;
        justify-content: flex-end;
        .href{
            color: #0033ff;
            text-decoration: underline;
            opacity: .8;
            cursor: pointer;
            &:hover{
                opacity: 1;
            }
        }
    }
    .otp-error{
        margin-top: 15px;
        color: var(--notify-alert);
    }
}
.form-otp-wrapper{
    
}

.text-error{
    margin-top: 15px;
    color: var(--notify-alert);
}