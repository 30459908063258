// *{
//   font-family: "Nunito", sans-serif;
//   font-optical-sizing: auto;
//   // font-weight: <weight>;
//   font-style: normal;
// }
.html-element.pp-timeline-card-content{
  line-height: 1.7em;
}

/* CSS HEX */
body{
  --davys-gray: #4D4846ff;
--jet: #302B29ff;
--eerie-black: #1E1D1Cff;
--night: #121212ff;
--onyx: #3C3F46ff;

/* CSS HSL */
--davys-gray: hsla(17, 5%, 29%, 1);
--jet: hsla(17, 8%, 17%, 1);
--eerie-black: hsla(30, 3%, 11%, 1);
--night: hsla(0, 0%, 7%, 1);
--onyx: hsla(222, 8%, 25%, 1);

/* SCSS HEX */
--davys-gray: #4D4846ff;
--jet: #302B29ff;
--eerie-black: #1E1D1Cff;
--night: #121212ff;
--onyx: rgb(110 118 137);
--onyx-dark:rgb(61 64 73);

/* SCSS HSL */
--davys-gray: hsla(17, 5%, 29%, 1);
--jet: hsla(17, 8%, 17%, 1);
--eerie-black: hsla(30, 3%, 11%, 1);
--night: hsla(0, 0%, 7%, 1);
--onyx: hsla(222, 8%, 25%, 1);
--bg-nav-bar:rgb(61 64 73);

/* SCSS RGB */
--davys-gray: rgba(77, 72, 70, 1);
--jet: rgba(48, 43, 41, 1);
--eerie-black: rgba(30, 29, 28, 1);
--night: rgba(18, 18, 18, 1);
--onyx: rgba(60, 63, 70, 1);

/* SCSS Gradient */
--gradient-top: linear-gradient(0deg, #4D4846ff, #302B29ff, #1E1D1Cff, #121212ff, #3C3F46ff);
--gradient-right: linear-gradient(90deg, #4D4846ff, #302B29ff, #1E1D1Cff, #121212ff, #3C3F46ff);
--gradient-bottom: linear-gradient(180deg, #4D4846ff, #302B29ff, #1E1D1Cff, #121212ff, #3C3F46ff);
--gradient-left: linear-gradient(270deg, #4D4846ff, #302B29ff, #1E1D1Cff, #121212ff, #3C3F46ff);
--gradient-top-right: linear-gradient(45deg, #4D4846ff, #302B29ff, #1E1D1Cff, #121212ff, #3C3F46ff);
--gradient-bottom-right: linear-gradient(135deg, #4D4846ff, #302B29ff, #1E1D1Cff, #121212ff, #3C3F46ff);
--gradient-top-left: linear-gradient(225deg, #4D4846ff, #302B29ff, #1E1D1Cff, #121212ff, #3C3F46ff);
--gradient-bottom-left: linear-gradient(315deg, #4D4846ff, #302B29ff, #1E1D1Cff, #121212ff, #3C3F46ff);
--gradient-radial: radial-gradient(#4D4846ff, #302B29ff, #1E1D1Cff, #121212ff, #3C3F46ff);
}




.button_icons {
  .button-render{
    background-color: var(--onyx-dark) !important;
  }
}

.button-undangan{
  background-color: var(--onyx-dark) !important;
}

.header_text{
  color: #fff;
  margin-left: 10px;
  text-transform: uppercase;
  font-family: "Cinzel", Sans-serif;
}

.header_text_cutom{
  color: #fff;
  margin-left: 10px;
  font-family: "Great Vibes", cursive;
  margin-bottom: 10px;
}

.dancing_script{
  font-family: "Dancing Script", cursive !important; 
  font-size: 18px !important;
  margin-bottom: 10px !important;
}

.react-photo-album--photo{
  // &.animated{
  //   display: none !important;
  // }

  display: none !important;
  &.animated {
    &.zoomIn{
      display: block !important;
    }
  }
}
.animated_hide{
  opacity: 0;
  
}
.animated{
  opacity: 1 !important;
  
}

.elementor-4685 .elementor-element.elementor-element-50e69ed .elementor-heading-title, .elementor-14997 .elementor-element.elementor-element-111cd6c8 .elementor-heading-title{
  text-transform: none !important;
  font-family: "Great Vibes", cursive !important;
  color: #fff !important;
}
.elementor-4685 .elementor-element.elementor-element-7509c689 .elementor-heading-title{
  color: #fff !important;
}

.elementor-4685 .elementor-element.elementor-element-63d1e54f .elementor-heading-title, .elementor-4685 .elementor-element.elementor-element-6f328f97 .elementor-heading-title{
  font-family: "Dancing Script", cursive !important; 
  font-size: 40px !important;
}

.elementor-4685 .elementor-element.elementor-element-53ebaf06 .elementor-heading-title, .elementor-14997 .elementor-element.elementor-element-995b418 .elementor-heading-title{
  font-family: "Great Vibes", cursive !important;
}

.elementor-4685 .elementor-element.elementor-element-21fec2e1 > .elementor-element-populated, .elementor-4685 .elementor-element.elementor-element-5864d470 > .elementor-element-populated, .elementor-4685 .elementor-element.elementor-element-5b965994:not(.elementor-motion-effects-element-type-background), .elementor-4685 .elementor-element.elementor-element-5b965994 > .elementor-motion-effects-container > .elementor-motion-effects-layer, .elementor-4685 .elementor-element.elementor-element-61620564:not(.elementor-motion-effects-element-type-background), .elementor-4685 .elementor-element.elementor-element-61620564 > .elementor-motion-effects-container > .elementor-motion-effects-layer{
  padding-top: 0 !important;
  //padding-bottom: 0 !important;
}

.elementor-4685 .elementor-element.elementor-element-5b965994:not(.elementor-motion-effects-element-type-background), .elementor-4685 .elementor-element.elementor-element-5b965994 > .elementor-motion-effects-container > .elementor-motion-effects-layer,  .elementor-4685 .elementor-element.elementor-element-61620564:not(.elementor-motion-effects-element-type-background), .elementor-4685 .elementor-element.elementor-element-61620564 > .elementor-motion-effects-container > .elementor-motion-effects-layer{
  margin-top: -1px;
}

// .elementor-4685 .elementor-element.elementor-element-5864d470 > .elementor-widget-wrap > .elementor-widget:not(.elementor-widget__width-auto):not(.elementor-widget__width-initial):not(:last-child):not(.elementor-absolute){
//   margin-bottom: -10px;
// }

.elementor-4685 .elementor-element.elementor-element-14754dd5 .elementor-heading-title, .elementor-4685 .elementor-element.elementor-element-29fe39b5 .elementor-heading-title{
  margin-bottom: -12px;
}

.elementor-4685 .elementor-element.elementor-element-5fd9d861{
  opacity: 1 !important;
}

.elementor-4685 .elementor-element.elementor-element-62bf653d{
  opacity:  1 !important;
  margin-bottom: 70px;
}